<template>
	<div class="page">
		<webHeader :currentIndex="5"></webHeader>
		<div class="centerMain">
			<navLeft :current="2" :navList="navList" @exchange="exchange" :title="$t('webHeader.personCenter')"></navLeft>
			<div class="mainPage">
				<!--身份验证-->
				<div class="verification" v-if="isDisplay">
					<van-row>
						<van-cell
						  center
						  :title="$t('set.nickname')"
						  is-link
						  @click="setnicknameshow"
						  ><template #default>
						    <span v-if="setinfo.nickname">{{ $t('set.bind') }}</span>
						    <span v-else>{{ $t('set.unbind') }}</span>
						  </template>
						</van-cell>
						<van-popup class="setting" v-model="setnickname" >
						  <h3>{{ $t('set.setnick') }}</h3>
						  <van-row class="text">
						    <van-cell-group>
						      <van-field
						        v-model="nickname"
						        center
						        clearable
						        :label="$t('set.nickname')"
						        :placeholder="$t('set.notice4')"
						      />
						    </van-cell-group>
						  </van-row>
						  <!-- 按钮 -->
						  <button class="button" style="border-right: 1px solid white;" @click="closenick">
						    {{ $t('common.cancel') }}
						  </button>
						  <button class="button" @click="bindnick">{{ $t('common.confirm') }}</button>
						</van-popup>
						<van-cell
						  center
						  :title="$t('set.editpass')"
						  is-link
						  @click="setpasswordshow"
						/>
						<van-popup class="setting" v-model="setpassword" >
						  <h3>{{ $t('editpass.title')}}</h3>
						  <van-row >
						    <van-cell-group>
						      <van-field
						        v-model="form.old_password"
						        :type="type"
						        :right-icon="eye"
						        :placeholder="$t('editpass.notice5')"
						        @click-right-icon="openeye"
						      >
						        <template #left-icon>
						          <van-icon class="iconfont" class-prefix="icon" name="mima" />
						        </template>
						      </van-field>
						      <van-field
						        v-model="form.password"
						        :type="type"
						        :right-icon="eye"
						        :placeholder="$t('editpass.notice3')"
						        @click-right-icon="openeye"
						      >
						        <template #left-icon>
						          <van-icon class="iconfont" class-prefix="icon" name="mima" />
						        </template>
						      </van-field>
						      <van-field
						        v-model="form.password2"
						        :type="type"
						        :right-icon="eye"
						        :placeholder="$t('editpass.notice4')"
						        @click-right-icon="openeye"
						      >
						        <template #left-icon>
						          <van-icon class="iconfont" class-prefix="icon" name="mima" />
						        </template>
						      </van-field>
						    </van-cell-group>
						  </van-row>
						  <van-button class="mybtn" @click="submit">{{$t('editpass.editbtn')}}</van-button>
						</van-popup>
						<van-cell
						  center
						  :title="$t('set.editpass_pay')"
						  is-link
						  @click="setpaypasswordshow"
						  
						/>
						<van-popup class="setting" v-model="setpaypassword" >
						  <h3>{{ $t('editpass.titlepay')}}</h3>
						 <van-row>
						   <van-cell-group>
						     <van-field
						       v-model="form2.old_password"
						       :type="type"
						       :right-icon="eye"
						       :placeholder="$t('editpass.payhiti1')"
						       @click-right-icon="openeye"
						     >
						       <template #left-icon>
						         <van-icon class="iconfont" class-prefix="icon" name="mima" />
						       </template>
						     </van-field>
						 
						     <van-field
						       v-model="form2.password"
						       :type="type"
						       :right-icon="eye"
						       :placeholder="$t('editpass.payhiti2')"
						       @click-right-icon="openeye"
						       maxlength="6"
						     >
						       <template #left-icon>
						         <van-icon class="iconfont" class-prefix="icon" name="mima" />
						       </template>
						     </van-field>
						     <van-field
						       v-model="form2.password2"
						       :type="type"
						       :right-icon="eye"
						       :placeholder="$t('editpass.payhiti3')"
						       @click-right-icon="openeye"
						       maxlength="6"
						     >
						       <template #left-icon>
						         <van-icon class="iconfont" class-prefix="icon" name="mima" />
						       </template>
						     </van-field>
						   </van-cell-group>
						 </van-row>
						 <van-button class="mybtn" @click="submit2">{{
						   $t("editpass.editbtn")
						 }}</van-button>
						</van-popup>
					</van-row>	
				</div>
				<div class="verificationForm" v-if="!isDisplay">
					<div class="title">
						<div class="nav" @click="back()">{{$t('webTitle.back')}}</div>
					</div>
					
				</div>
			</div>
		</div>
		<webFooter></webFooter>
	</div>
</template>

<script>
	import webHeader from "@/components/web/webHeader.vue";
	import navLeft from "@/components/web/navLeft.vue";
	import webFooter from "@/components/web/webFooter.vue";
	export default {
	    components: { webHeader, navLeft, webFooter },
	    data() {
			return{
				currentIndex:5,
				navList:[
					{
						icon:require('../../../assets/img/web/iconAuth.png'),
						name:this.$t('home.gj_auth'),
						path:'/web/my/index'
					},
					{
						icon:require('../../../assets/img/web/iconMessage.png'),
						name:this.$t('home.message'),
						path:'/web/my/Contact_list'
					},
					// {
					// 	icon:require('../../../assets/img/serve_my.svg'),
					// 	name:this.$t('home.serverurl'),
					// 	path:this.serverurl,
					// },
					{
						icon:require('../../../assets/img/web/iconSetting.png'),
						name:this.$t('home.set'),
						path:'/web/my/setting',
					},
				],
				isDisplay:true,
				// 昵称弹窗
				setnickname: false,
				setpassword: false,
				setpaypassword:false,
				nickname: '',
				setinfo: {
				  nickname: '',
				},
				form: {
				  // username: '',
				  old_password: '',
				  password: '',
				  password2: '',
				  // code: ''
				},
				form2: {
				  // username: '',
				  old_password: '',
				  password: '',
				  password2: '',
				  // code: ''
				},
				type: 'password',
				eye: 'closed-eye',
			}
		},
		created() {
			this.getinfo();
		},
		methods:{
			async getinfo() {
			  const { data } = await this.$http.get('/home/home/getsetinfo')
			  if (data) {
			    if (data.code === 200) {
			      this.setinfo = data.data
			    }
			  }
			},
			// 设置昵称
			setnicknameshow() {
			  if (this.setinfo.nickname) {
			    this.$toast.fail(this.$t('set.nicktoast'))
			    return false
			  }
			  this.setnickname = true
			},
			//关闭
			closenick() {
			  this.nickname = ''
			  this.setnickname = false;
			},
			setpasswordshow() {
			  this.setpassword = true
			},
			setpaypasswordshow() {
			  this.setpaypassword = true
			},
			// //确定绑定邮箱
			async bindnick() {
			  if (!this.nickname) {
			    this.$toast.fail(this.$t('set.notice4'))
			    return false
			  }
			  const { data } = await this.$http.post('/home/home/bindnickname', {
			    nickname: this.nickname
			  })
			  if (data) {
			    if (data.code === 200) {
			      this.$toast.success(this.$t('set.bind'))
			      this.setnickname = false;
				  this.getinfo();
			    } else {
			      this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
			    }
			  }
			},
			// 查看密码
			openeye(event) {
			  if (this.type === 'password') {
			    this.eye = 'eye'
			    this.type = 'text'
			  } else {
			    this.eye = 'closed-eye'
			    this.type = 'password'
			  }
			},
			// 修改密码
			async submit() {
			  if (!this.form.old_password || !this.form.password2 || !this.form.password) {
			    this.$toast.fail(this.$t('editpass.complate'))
			    return false
			  }
			  if (this.form.password !== this.form.password2) {
			    this.$toast.fail(this.$t('editpass.eqpass'))
			    return false
			  }
			  const { data } = await this.$http.post('/home/home/changepass', this.form)
			  if (data) {
			    if (data.code === 200) {
				  this.setpassword = false;
			      this.$toast.success(
			        this.getlang(data.msg, localStorage.getItem('lang'))
			      )
			    } else {
			      this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
			    }
			  }
			},
			// 修改密码
			async submit2() {
			  if (!this.form2.old_password || !this.form2.password2 || !this.form2.password) {
			    this.$toast.fail(this.$t("editpass.complate"));
			    return false;
			  }
			  if (this.form2.password !== this.form2.password2) {
			    this.$toast.fail(this.$t("editpass.eqpass"));
			    return false;
			  }
			  const { data } = await this.$http.post(
			    "/home/home/changepaypass",
			    this.form2
			  );
			  if (data) {
			    if (data.code === 200) {
				  this.setpaypassword = false;
			      this.$toast.success(
			        this.getlang(data.msg, localStorage.getItem("lang"))
			      );
			    } else {
			      this.$toast.fail(
			        this.getlang(data.msg, localStorage.getItem("lang"))
			      );
			    }
			  }
			},
			// 返回
			toadvanced() {
				this.isDisplay = false;
			  //this.$router.push('/person/advanced')
			},
			
			back(){
				this.isDisplay = true;
			},
			exchange(data){
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.page{ background-color: #141416; min-height: 100vh;
		.centerMain{ padding:20px 0; width:1200px; margin:0 auto; display: flex; justify-content: space-between;
			.mainPage{ min-height: calc(100vh - 80px);background-color: #18191d; color:#fff; width:1000px; text-align: left;}
		}
	}
	.verification{
		.van-cell{ background-color:transparent!important; color:#eee;}
		// 弹层
		.setting {
		  width: 30%;
		  border-radius: 0.4rem;
		  color: #004ea3 !important;
		  [class*='van-hairline']:after {
		    border: none;
		  }
		  h3 {
		    padding-top: 4%;
		    padding-bottom: 4%;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    font-weight: 400;
		    font-size: 1rem;
		    border-bottom: 0.02667rem solid #e5e5e5;
		  }
		  .text {
		    width: 50%;
		    height: 55%;
		    border: 0;
		    .van-cell-group {
		      background-color: #fff;
		      margin-top: 1.33333rem;
		      margin-bottom: 1.33333rem;

		    }
		  }
		  .button {
			cursor: pointer;
			width:50%;
		    padding-top: 4%;
		    padding-bottom: 4%;
		    border: 0;
		    background-color: #fff;
		    border-top: 0.02667rem solid #e5e5e5;
		    color: #004ea3;
		    font-size: 1.06667rem;
		  }
		  .mybtn {
			width:300px;
			display: block;
			margin:20px auto;
		    letter-spacing: 0.53333rem;
		    height: 4rem;
		    font-size: 0.93333rem;
		    color: #fff;
		    background-color: #004ea3;
		    border-radius: 0.53333rem;
		    border: none;
		    box-shadow: 0 0 1.33333rem #004ea3;
		  }
		}
	}
	.verificationForm{ padding:20px;
		.title{ padding-bottom: 10px; border-bottom:1px solid #ccc;
			.nav{ cursor: pointer; display: inline-block; padding:5px 10px; border:1px solid #fff; border-radius: 5px; margin-right: 10px;}
			.titText{ display: inline-block; font-size: 18px; font-weight: 600;}
		}
		
	}
	
</style>