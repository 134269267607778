<template>
	<div class="navLeft">
		<div class="title">{{title}}</div>
		<div class="navList">
			<div  v-for="(item,index) in navList" :key="index" >
				<div class="item" :class="currentIndex==index?'select':''" @click="exchange(index,'')">
				<router-link :to="item.path">
				<img class="icon" :src="item.icon" v-if="item.icon"/>
				<span class="text" :title="item.name">{{item.name}}</span>
				</router-link>
				</div>
				<div class="subitem" v-if="item.children" v-for="(it,ind) in item.children" :key="it.id" @click="exchange(index,ind)">
					<router-link :to="it.path">
					<img class="icon" :src="it.icon" v-if="it.icon"/>
					<span class="text" :title="it.name">{{it.name}}</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			title:{
				type:String,
				default:'个人中心',
			},
			navList:{
				type:Array,
				default:[
					{
						icon:'https://jk53y.com/Uploads//wallet/2023-07-06/64a6858b200e5.jpg',
						tname:'帐号管理',
						path:'/web/my/index'
					}
				],
			},
			current:{
				type:Number,
				default:0
			}
			
		},
		data(){
			return{
				currentIndex:0,
			}
		},
		created() {
			this.currentIndex = this.current;
		},
		methods:{
			exchange(index,ind){
				this.currentIndex = index;
				if(ind){
					if(this.navList[index][ind].path){
						this.$router.push(this.navList[index][ind].path);
					}
				}else{
					if(this.navList[index].path){
						this.$router.push(this.navList[index].path);
					}
				}
				let data = {index,ind}
				this.$emit('exchange',data)
			}
		}
		
	}
</script>

<style lang="less" scoped>
.navLeft{ background-color:#18191d; width:210px; margin-right: 10px;min-height: calc(100vh - 80px); text-align: left;
	.title{padding-left: 20px;height: 56px;font-weight: 700;font-size: 16px;color: #fff;display: flex;align-items: center;padding-left: 20px;}
	.navList{
		.item{ min-height:56px; line-height: 56px; font-size: 14px; color:#828ea1; cursor:pointer; padding-left:15px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;
			&:hover{background:#23262f; 
				.text{color:#fff;}
			}
			.subitem{ min-height:56px; line-height: 56px; font-size: 14px; color:#828ea1; cursor:pointer; padding-left:30px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;}
			.text{color:#828ea1;}
			.icon{ width: 16px; height:16px; margin-right:10px; display: inline-block; vertical-align: middle;}
			&.select{ background:#23262f; 
				.text{color:#fff;}
			}
		}
	}
}	

</style>